body{
  background: black;
}
.row .zeropadcol{
    padding-left: 0;
    padding-right: 0;
}

.rough{
  background-color: #1e90ff #87ceeb;
  filter: drop-shadow();

}

/* Navbar Toggle start */
.containerX {
  display: inline-block;
  cursor: pointer;
}

.bar1, .bar2, .bar3 {
  display: block;
  width: 35px;
  height: 3.5px;
  background-color: whitesmoke;
  margin: 6px 0;
  transition: 0.4s;
  border-radius: 15px;
}


.change .bar1 {
  transform: translate(0, 8.85px) rotate(-47deg);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  transform: translate(0, -8.85px) rotate(47deg);
}

/* Navbar Toggle End */



  
  .typewriter{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 7.5vw;
    
  }
  

  
  .typewriter .name {
    opacity: 0;
    font-family: "Work Sans", sans-serif;
    margin: 0 auto auto auto;
    background: linear-gradient(to right, dodgerblue, darkgray, #778899);
    font-weight: 800;
    font-size: 4vw;  
    white-space: nowrap;
    width: 30vw;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* -webkit-box-reflect: below -10px linear-gradient(transparent, #3333); */
    overflow: hidden;
    animation: shring-animation 2.5s steps(40,end) 1s  forwards,
     blink 0.3s step-end  infinite alternate forwards;
  }

  
  @keyframes shring-animation {
    0% {
      background-position: 0 0;
      opacity: 0;
      width: 0;
    }
    1% {
      background-position: 0 0;
      opacity: 1;
      border-right: 1px solid orange;
    }
    50% {
      background-position: 150px 0;
      opacity: 1;
      border-right: 1px solid orange;
    }
    100% {
      background-position: 400px 0;
      opacity: 1;
      border-right: 1px solid orange;
    }
  }

 

  
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }

  @media (max-width: 768px) {
    .typewriter .name {
        font-size: 32px;
        width: 47vw;
    }
  }
  @media (max-width: 480px) {
    .typewriter .name {
        font-size: 32px;
        width: 70vw;
    }
  }
  